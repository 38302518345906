// Vue
import Vue from 'vue'
// Constants
import { ADDONS, MENU_URL, HORECA_URL } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DataColumns from '@/components/ui/DataColumns'
import StickerOrdersForm from '@/components/elements/stickerOrders/StickerOrdersForm'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapState } from 'vuex'
// Services
import {
  getAnalyticsDataByPlaceId,
  getOrdersDataForDashboardByPlaceId,
  getPlaceById
} from '@/services/place'
// Filters
import { formatNumber, formatDate, formatPrice, formatGoogleAddressObject } from '@/filters'
// Utils
import { cloneDeep, get, isNil } from 'lodash'
// Charts
import VueApexCharts from 'vue-apexcharts'
// Charts
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

const moment = require('moment')

// Days to date selector
const sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD')
const fifteenDaysAgo = moment().subtract(15, 'days').format('YYYY-MM-DD')
const thirtyDaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD')

export default {
  name: 'PlacesDetail',
  components: {
    CardContainer,
    DataColumns,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  filters: { formatDate },
  data() {
    return {
      // Data
      id: get(this.$route, 'params.id', null), // UID del establecimiento
      placeData: null,
      visitStatistics: [],
      orderStatistics: [],
      // Chart
      orderSeries: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        noData: {
          text: 'No hay datos que cargar en las fechas determinadas'
        }
      },
      // options
      dateSelected: sevenDaysAgo,
      dateOptions: [
        {
          label: '7 días',
          value: sevenDaysAgo
        },
        {
          label: '15 días',
          value: fifteenDaysAgo
        },
        {
          label: '30 días',
          value: thirtyDaysAgo
        }
      ],
      // others
      processingRequest: false
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Configuraciones de los addOns del establecimiento
     *
     * @return {object}
     */
    placeAddonConfigs() {
      const addOnConfigs = get(this.placeData, 'addOnConfigs', [])

      return addOnConfigs.reduce((sumAddOns, addOn) => {
        sumAddOns[addOn.id] = { ...(addOn.configFields || {}) }
        return sumAddOns
      }, {})
    },
    /**
     * Dirección del establecimiento
     *
     * @return {string}
     */
    placeAddress() {
      const address = get(this.placeAddonConfigs, `${ADDONS.contact}.place`, {})
      return formatGoogleAddressObject(address)
    },
    /**
     * Nombre del establecimiento
     *
     * @return {string}
     */
    placeName() {
      const name = get(this.placeAddonConfigs, `${ADDONS.place}.name`, null)
      return !isNil(name) ? name : get(this.placeData, 'name', 'Sin nombre')
    },
    /**
     * URL del establecimiento
     *
     * @return {string}
     */
    placeUrl() {
      return `${MENU_URL}${this.placeData.url}`
    }
  },
  watch: {
    dateSelected() {
      this.getEveryNeededData()
    }
  },
  async mounted() {
    // Obtenemos todos los datos necesarios de la APP
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Navegamos a la gestión de los menus/cartas
     */
    handleClickManageMenu() {
      this.routerPushTo({
        name: 'places-manage-menus',
        params: {
          id: this.placeData.id
        }
      })
    },
    /**
     * Abrimos una pestaña hacia la aplicación del HORECA
     */
    handleClickAppHoreca() {
      window.open(`${HORECA_URL}login-brand?placeId=${this.placeData.id}`, '_blank')
    },
    /**
     * Abrimos una modal para realizar un pedido de pegatinas
     */
    handleClickStickerOrder() {
      this.modifyAppDialog({
        title: 'Crear pedido de pegatinas',
        contentComponent: cloneDeep(StickerOrdersForm),
        contentComponentProps: {
          placeId: this.placeData.id
        },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Obtenemos todos los datos a mostrar en el componente
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true
        // Datos del establecimiento
        this.placeData = await getPlaceById(this.id, {
          includeAddOnsConfigs: true,
          includeSubscriptions: false
        })

        if (isNil(this.placeData)) {
          throw new Error('No existe ningún establecimiento asociado al ID indicado')
        }
        // Datos visitas
        await this.setVisitStatistics(this.placeData.id)
        // Datos pedidos
        await this.setOrderStatistics(this.placeData.id)
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos datos de visitas
     *
     * @param {string} id - UID del establecimiento
     */
    async setVisitStatistics(id) {
      const data = await getAnalyticsDataByPlaceId(
        id,
        this.dateSelected,
        moment().format('YYYY-MM-DD')
      )

      this.visitStatistics = [
        {
          label: 'Usuarios',
          value: formatNumber(get(data, 'totalUsers', 0)),
          ratio: get(data, 'totalUsersIncrementPeriods', 0)
        },
        {
          label: 'Visitas totales',
          value: formatNumber(get(data, 'totalSessions', 0)),
          ratio: get(data, 'totalSessionsIncrementPeriods', 0)
        },
        {
          label: 'Visitas QR (establecimiento)',
          value: formatNumber(get(data, 'totalVisitsQR', 0)),
          ratio: get(data, 'totalVisitsQRIncrementPeriods', 0)
        },
        {
          label: 'Visitas Enlace (otros medios)',
          value: formatNumber(get(data, 'totalVisitsURL', 0)),
          ratio: get(data, 'totalVisitsURLIncrementPeriods', 0)
        }
      ]
    },
    /**
     * Obtenemos datos de pedidos
     *
     * @param {string} id - UID del establecimiento
     */
    async setOrderStatistics(id) {
      const daysAgo = moment().diff(moment(this.dateSelected), 'days')
      // Datos de pedidos
      const { periodOrdersData, salesData } = await getOrdersDataForDashboardByPlaceId(id, daysAgo)
      // Columns
      if (!isNil(salesData)) {
        this.orderStatistics = [
          {
            label: 'Pedidos totales',
            value: formatNumber(get(salesData, 'totalOrders', 0), true),
            ratio: get(salesData, 'totalOrdersIncrement', 0)
          },
          {
            label: 'Ticket medio',
            value: formatPrice(get(salesData, 'averageTicket', 0), 'EUR', true),
            ratio: get(salesData, 'averageTicketIncrement', 0)
          },
          {
            label: 'Ventas totales',
            value: formatPrice(get(salesData, 'totalAmount', 0), 'EUR', true),
            ratio: get(salesData, 'totalAmountIncrement', 0)
          }
        ]
      } else {
        this.orderStatistics = []
      }

      // Chart
      if (!isNil(periodOrdersData) && periodOrdersData.length > 0) {
        const parseData = periodOrdersData.reduce(
          (sumItems, item) => {
            // Parseamos fecha
            const partsDate = item.createdDate.split('/')
            const parsedDate = `${partsDate[2]}-${partsDate[1]}-${partsDate[0]}`

            // Agrupamos valores en sus objetos
            sumItems.averageTickets = [...sumItems.averageTickets, formatNumber(item.averageTicket)]
            sumItems.dates = [...sumItems.dates, formatDate(parsedDate, 'D MMM')]
            sumItems.totalOrders = [...sumItems.totalOrders, item.totalOrders]
            return sumItems
          },
          {
            averageTickets: [],
            dates: [],
            totalOrders: []
          }
        )
        // Eje Y
        this.orderSeries = [
          {
            name: 'Pedidos',
            data: parseData.totalOrders
          },
          {
            name: 'Ticket medio',
            data: parseData.averageTickets
          }
        ]
        // Eje X
        this.chartOptions.xaxis = {
          categories: parseData.dates
        }
      } else {
        this.orderSeries = []
      }
    }
  }
}
