// Components
import FormButtons from '@/components/ui/FormButtons'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import {
  createStickerOrder,
  deleteStickerOrderById,
  getStickerOrderById,
  updateStickerOrderById
} from '@/services/stickerOrder'
import { getPlaceById } from '@/services/place'
// Utils
import { get, isNil } from 'lodash'
import { getEnvironmentVariable, downloadFileFromUrl } from '@/utils'
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'StickerOrdersForm',
  components: { FormButtons, GoogleMapAutocomplete, VuetifyContentLoading },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    placeId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Form
      formFields: {
        address: null,
        addressExtra: null,
        status: null,
        stickers: []
      },
      formFieldsValidations: {
        address: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        },
        status: {
          required: 'Campo obligatorio'
        },
        stickers: {
          id: {
            required: 'Campo obligatorio'
          },
          amount: {
            minimumIsZero: 'Mínimo 0 y al menos uno mayor a 0'
          }
        }
      },
      // Others
      placeData: null,
      processingRequest: true,
      stickerOrderData: null
    }
  },
  computed: {
    ...mapState('stickerOrders', ['stickerOrdersStatus', 'stickerOrdersTypes']),
    /**
     * UID del pedido en base de datos
     *
     * @return {string}
     */
    currentId() {
      const id = get(this.stickerOrderData, 'id', null)
      return !isNil(id) ? id : this.id
    },
    /**
     * UID del establecimiento asociado al pedido
     *
     * @return {string}
     */
    currentPlaceId() {
      const placeId = get(this.stickerOrderData, 'placeId', null)
      return !isNil(placeId) ? placeId : this.placeId
    },
    /**
     * Estados disponibles en los pedidos de pegatinas
     *
     * @return {array}
     */
    parsedStickerOrdersStatus() {
      return Object.values(this.stickerOrdersStatus)
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      await this.getEveryNeededData()
    })
  },
  methods: {
    /**
     * Inicializamos/obtenemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true
        // Edición
        if (!isNil(this.currentId)) {
          // Datos del pedido
          this.stickerOrderData = await getStickerOrderById(this.currentId)
          // Obtenemos datos del establecimiento
          this.placeData = await getPlaceById(this.stickerOrderData.placeId)
          // Set form variables
          this.setFormFieldsValues(this.stickerOrderData)
        } else {
          if (isNil(this.currentPlaceId)) {
            throw new Error('No se ha indicado un establecimineto')
          }
          // Obtenemos datos del establecimiento
          this.placeData = await getPlaceById(this.currentPlaceId)
          // Set form variables
          this.setFormFieldsValues()
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
        // hide dialog
        this.hideDialog()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Devuelve la etiqueta del tipo de pegatina
     *
     * @param {string} value - tipo de pegatina (id)
     * @return {string} - tipo de pegatina (etiqueta)
     */
    getStickerOrdersTypesLabel(value) {
      return get(this.stickerOrdersTypes, `${value}.label`, 'No definido')
    },
    /**
     * Cuando usuario pulsa sobre botón de "eliminar"
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Removing item
          await deleteStickerOrderById(this.stickerOrderData.id)
          // Emitimos evento general para que cualquier lista
          // a la escucha, recargue su listado
          this.$root.$emit('reload-list')
          // Hide dialog
          this.hideDialog()
        },
        actionButtonText: 'Borrar',
        text: 'Estas seguro que deseas eliminar el pedido?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Descarga el código QR en el cliente
     *
     * @param {string} url - url del fichero a descargar
     */
    async handleDownloadButton(url) {
      await downloadFileFromUrl(url)
    },
    /**
     * Mostramos el componente "appAlert"
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Cuando usuario pulsa sobre botón de cancelar
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Ocultamos "diálogo"
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Establecemos los valores iniciales de los
     * campos del formulario
     *
     * @param {object} data - objeto con los datos a establecer
     */
    setFormFieldsValues(data = {}) {
      this.formFields.address = get(data, 'address', this.formFields.address)
      this.formFields.addressExtra = get(data, 'addressExtra', this.formFields.addressExtra)
      this.formFields.status = get(data, 'status', this.formFields.status)

      // Preparamos "stickers" para que contenga al menos cada uno de los tipos
      // de pegatinas existentes (macheando con los datos ya almacenados)
      const stickersValues = get(data, 'stickers', this.formFields.stickers)

      this.formFields.stickers = Object.values(this.stickerOrdersTypes).reduce((accTypes, type) => {
        const currentTypeIndex = stickersValues.findIndex((stickersValue) => {
          return stickersValue.id === type.value
        })

        if (currentTypeIndex > -1) {
          accTypes[currentTypeIndex] = {
            id: type.value,
            amount: stickersValues[currentTypeIndex].amount
          }
        } else {
          accTypes.push({
            id: type.value,
            amount: 0
          })
        }

        return accTypes
      }, stickersValues)
    },
    /**
     * "Callback" antes de la validación
     */
    beforeSubmit() {
      // En la creación de los pedidos
      // el estado por defecto es "pending"
      if (isNil(this.currentId)) {
        this.formFields.status = this.stickerOrdersStatus.pending.value
      }
    },
    /**
     * "Callback" lanzado tras la validación de los
     * campos por parte de "Vuelidate"
     */
    async afterSubmit() {
      try {
        // Modificamos el "UID" del tipo de la pegatinas (array "stickers")
        // por el "UID" correcto que deberá almacenarse en BD,
        // en este punto el "UID" es la "etiqueta"
        const stickerOrdersTypes = Object.values(this.stickerOrdersTypes)
        this.formFields.stickers = this.formFields.stickers.reduce((accStickers, sticker) => {
          const currentStickerOrdersTypesIndex = stickerOrdersTypes.findIndex(
            (stickersValue) => stickersValue.label === sticker.label
          )

          if (currentStickerOrdersTypesIndex > -1) {
            accStickers[currentStickerOrdersTypesIndex] = {
              id: stickerOrdersTypes[currentStickerOrdersTypesIndex].value,
              amount: sticker.amount
            }
          }
          return accStickers
        }, this.formFields.stickers)

        if (isNil(this.currentId)) {
          if (isNil(this.currentPlaceId)) {
            throw new Error('No se ha indicado un establecimineto')
          }

          // Creamos
          this.stickerOrderData = await createStickerOrder({
            ...this.formFields,
            brand: getEnvironmentVariable('VUE_APP_BRAND'),
            placeId: this.currentPlaceId,
            qrCode: this.placeData.qrCode
          })
        } else {
          // Actualizamos
          await updateStickerOrderById({
            ...this.formFields,
            id: this.currentId
          })
        }

        // Emitimos evento general para que cualquier lista
        // a la escucha, recargue su listado
        this.$root.$emit('reload-list')

        // Información resultados acción de salvado
        this.modifyAppAlert({
          text: 'Los cambios se guardaron correctamente',
          visible: true
        })

        // Ocultamos modal
        this.hideDialog()
      } catch (error) {
        // show error
        this.handleError(error.message)
      }
    }
  },
  // Validations with Vuelidate
  validations() {
    const currentFormFields = this.formFields
    return {
      formFields: {
        address: {
          required
        },
        addressExtra: {},
        status: {
          required
        },
        stickers: {
          $each: {
            id: {
              required
            },
            amount: {
              minimumIsZero: () => {
                const isNull = isNil(currentFormFields.stickers)
                const everyIsZero = currentFormFields.stickers.every(
                  (sticker) => typeof sticker.amount === 'number' && sticker.amount > -1
                )
                const atLeastOneIsMoreThanZero = currentFormFields.stickers.some(
                  (sticker) => sticker.amount > 0
                )

                return !isNull && everyIsZero && atLeastOneIsMoreThanZero
              }
            }
          }
        }
      }
    }
  }
}
